@use '@angular/material' as mat;
@import "@angular/material/theming";
@import "mixins/palette";
@import "mixins/typography";

@include mat.all-component-typographies($fontConfig);
@include mat.core();
@include mat.all-component-themes($theme-default-light);

//region theming app
.page-header {
  background-color: map-get($config-theme-primary, 200);
}

.step-navigation-wrapper {
  .current-step {
    background-color: map-get($config-theme-accent, 200);
  }
}

//endregion theming app
